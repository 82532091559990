import React from 'react'
import styles from './error.module.scss'

const ErrorTemplate = () => {
  return (
    <div className={styles.el}>
      <div className={styles.el__header}>
        <h1 className={styles.el__code}>404</h1>
        <h2 className={styles.el__title}>PAGE NOT FOUND</h2>
      </div>
      <div className={styles.el__credit}>
        Pixel artwork by{' '}
        <a href="http://ansimuz.com" target="_blank" rel="noopener noreferrer">
          ansimuz.com
        </a>
      </div>
      <div className={styles.el__bg}>
        <div className={styles.el__rain} />
        <div className={styles.el__bg__front}>
          <div className={styles.el__bg__front__top} />
          <div className={styles.el__bg__front__middle} />
          <div className={styles.el__bg__front__bottom}>
            <div className={styles.el__bg__tree} />
            <div className={styles.el__bg__ghost} />
          </div>
        </div>
        <div className={styles.el__bg__mid}>
          <div className={styles.el__bg__mid__top} />
          <div className={styles.el__bg__mid__middle} />
          <div className={styles.el__bg__mid__bottom} />
        </div>
        <div className={styles.el__bg__back} />
      </div>
    </div>
  )
}

export default ErrorTemplate
